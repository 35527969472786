.icon-spin {
  -webkit-animation: icon-spin 2s infinite linear;
  animation: icon-spin 2s infinite linear;
}

@-webkit-keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.react-datepicker-popper {
  z-index: 99 !important;
}

.react-datepicker-wrapper {
  display: inherit !important;
}

.react-datepicker__input-container {
  display: inherit !important;
}

.react-datepicker__time-box {
  width: 100px !important;
}

.react-datepicker__time-container {
  width: 100px !important;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 110px !important;
}

.dropzone-form {
  margin: 10px 0 10px 0;
  height: 100px;
  border-width: 2px;
  border-color: rgb(102, 102, 102);
  border-style: dashed;
  border-radius: 5px;
  cursor: pointer;
}

.rt-th {
  text-align: left;
}

.rt-resizer {
  z-index: 0 !important;
}

input[disabled] {
  -webkit-text-fill-color: #75787d;
  opacity: 1;
}

.button-top-right-badge {
  position: absolute;
  background-color: red;
  border-radius: 30px;
  padding: 6px;
  top: -5px;
  right: 10px;
}

.badge {
  font-size: 65%;
}

ol.progtrckr li.progtrckr-todo {
  z-index: 1;
}

ol.progtrckr li.progtrckr-doing {
  z-index: 1;
}

.header-flex-center {
  display: flex;
  align-items: center;
}

.ReactTable .rt-thead.-header {
  overflow-y: hidden;
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.15);
}

.media.active {
  border-left: 2px solid #009da0;
  padding-left: 10px;
}

.react-select__control {
  border: 1px solid #a6a9ae !important;
  min-height: 28px !important;
}
.react-select__value-container {
  padding: 0px 6px !important;
}
.react-select__input {
  margin: 0 !important;
  padding: 0 !important;
}
.react-select__single-value {
  font-weight: 400 !important;
  color: #75787d !important;
}
.react-select__placeholder {
  color: darken($body-bg, 20%) !important;
  font-weight: 400 !important;
}
.react-select__multi-value__label {
  font-weight: 400 !important;
  color: #75787d !important;
  height: 20px !important;
  margin-top: -2px !important;
}
.react-select__dropdown-indicator {
  color: #a6a9ae !important;
  padding: 4px !important;
}
.react-select__clear-indicator {
  color: #a6a9ae !important;
  padding: 4px !important;
}

.react-select__control--is-disabled {
  background-color: #eceff1 !important;
  -webkit-text-fill-color: #75787d !important;
  opacity: 1 !important;
}

.react-select__indicator-separator {
  background-color: #a6a9ae !important;
}

.react-select__menu {
  z-index: 1000 !important;
}

.input-required:enabled {
  border-left: 5px solid #ff586b;
}

.input-required-important {
  border-left: 5px solid #ff586b !important;
}

.input-required .react-select__control:not(.react-select__control--is-disabled) {
  border-left: 5px solid #ff586b !important;
}

small {
  font-size: 75%;
}

.mb-neg-7 {
  margin-bottom: -7px;
}

.display-inline-flex {
  display: inline-flex !important;
}

.sig-canvas {
  border: 1px solid #e8e8e8;
}

.card-hover {
  transition: box-shadow 0.3s;
}

.card-hover:hover {
  box-shadow: 0 0 15px rgba(33, 33, 33, 0.2);
  transform: scale(1.075);
}

.paypal-buttons {
  min-width: 0 !important;
}

.mt-neg-3 {
  margin-top: -3px !important;
}

.panel-header {
  margin-top: 2px;
  margin-bottom: 0.75rem;
  font-weight: 400;
  font-size: 1.5rem;
  font-family: $font-family-sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
}

.display-none {
  display: none !important;
}

.google-login-btn {
  background-color: #ffffff;
  font: Roboto-Medium;
  size: 14px;
  text-align: center !important;
}

.navbar-light {
  background-color: #ffffff;
  margin-left: -30px;
  position: fixed;
  top: 0;
  height: 45px;
  padding: 6px 250px 6px 12px;
  width: 100%;
  box-shadow: 0px 0px 30px -10px rgba(0, 0, 0, 0.42);
}

.login-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: rgb(244, 247, 250);
  background-size: cover;
}

.login-page.center-screen {
  flex-direction: column;
  position: relative;
  z-index: 1;
}
.login-page .logo-large {
  margin: 0 auto 20px;
}
.login-page .card {
  width: 475px;
  background-color: rgba(34, 34, 34, 0.7);
}
.login-page .card .card-body {
  padding: 30px 18px 20px;
  text-align: center;
}
.login-page .card .card-body .col-md-12 {
  padding: 0;
}
.login-page .card .card-body .btn.btn-primary.btn-block {
  width: 240px;
  height: 40px;
  padding: 0;
  margin: 0 auto;
  font-family: "poppinssemibold", sans-serif;
}
.login-page .card .card-body .form-control {
  text-align: left;
  color: var(--white);
  background-color: rgba(255, 255, 255, 0.2);
  padding: 0 35px;
  border-radius: 25px;
}
.login-page .card .card-body .form-control::-webkit-input-placeholder {
  color: var(--white);
}
.login-page .card .card-body .form-control:-moz-placeholder {
  color: var(--white);
  opacity: 1;
}
.login-page .card .card-body .form-control::-moz-placeholder {
  color: var(--white);
  opacity: 1;
}
.login-page .card .card-body .form-control:-ms-input-placeholder {
  color: var(--white);
}
.login-page .card .card-body .form-control:placeholder-shown {
  color: var(--white);
}
.center-screen {
  @extend .align-items-center;
  @extend .justify-content-center;
  @extend .d-flex;
  height: 100vh;
}

.modal-content-bg-grey {
  background: #f4f7fa;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent
    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='black'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>")
    center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
